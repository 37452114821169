import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, FloatingIcons } from 'components/organisms';
import {
  Advantages,
  Contact,
  Locations,
  HeroCard,
  Hero,
} from './components';
import { data as myData } from './data';
import { DefaultSEO } from 'components/DefaultSEO';
import Banner from './components/Banner';

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('md')]: {
    sectionAlternateForm: {
      maxWidth: '75%',
      margin: 'auto',
    },
  },
}));

const Landing = (): JSX.Element => {
  const classes = useStyles();
  const { advantages, partners, locations } = myData();

  return (
    <div>
      <Banner />
      <DefaultSEO
        title="THE CENTER SPACE | Flagship Business Lounge in Hong Kong | 坐落於中環核心的高端服務式辦公室"
        description="The Center is located at the heart of Central, Hong Kong's Central Business District. With a standing of 350 meters, The Center holds the title of Hong Kong’s fifth-highest skyscraper. Located on the 76th floor of the building, one of the highest co-working space in Hong Kong, our space offers panoramic views of Victoria Harbour and Hong Kong Skyline. The Center is also surrounded by major financial, banking, and government headquarters."
      />
      <Hero />
      <FloatingIcons />
      <Section>
        <Locations data={locations} />
      </Section>
      <Section>
        <Advantages data={advantages} />
      </Section>
      <Section>
        <HeroCard />
      </Section>
      <div className={classes.sectionAlternateForm} >
        <Section>
          <Contact id="contact-form" />
        </Section>
      </div>
    </div>
  );
};

export default Landing;
