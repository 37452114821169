import React from 'react';
import Grid from '@mui/material/Grid';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Advantages = ({ data, ...rest }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div {...rest}>
      <SectionHeader title={t('whatUniqueTitle')} titleVariant="h3" fadeUp />
      <Grid container spacing={4} justifyContent="center">
        {data.map((item: any, index: number) => (
          <Grid key={index} item xs={12} md={3} data-aos="fade-up">
            <DescriptionListIcon
              title={item.title}
              titleVariant="h6"
              subtitle={item.subtitle}
              subtitleVariant="body1"
              icon={
                <Image
                  src={item.icon}
                  alt={item.title}
                  style={{ width: 60, height: 60 }}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Advantages;
