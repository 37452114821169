import React from 'react';
import { CardBase, ContactForm } from 'components/organisms';

const Contact = ({ ...rest }): JSX.Element => (
  <div {...rest}>
    <CardBase withShadow data-aos="fade-up">
      <ContactForm />
    </CardBase>
  </div>
);

export default Contact;
