import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  gridWidth: {
    maxWidth: '90%',
    margin: 'auto',
  },
  avatar: {
    width: 90,
    height: 90,
    borderRadius: 0,
    background: 'transparent',
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 120,
    },
  },
}));

const Partners = ({ data, ...rest }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div {...rest}>
      <SectionHeader
        title={t('partnersTitle')}
        subtitle={t('partnersSubtitle')}
        titleVariant="h3"
        subtitleVariant="body1"
        fadeUp
      />
      <Grid container spacing={6} className={classes.gridWidth}>
        {data.map((item: any, index: number) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={4}
            sm={3}
            md={2}
            data-aos={'fade-up'}
          >
            <DescriptionListIcon
              icon={
                <Avatar className={classes.avatar}>
                  <Image src={item.logo} alt={item.name} />
                </Avatar>
              }
              title={item.name}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Partners;
