import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { LearnMoreLink, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardProduct } from 'components/organisms';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  exploreMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      right: 5,
      bottom: 5,
      padding: '3%',
    },
  },
  cardRoot: {
    position: 'relative',
    borderRadius: '10px',
    width: 'auto',
    maxWidth: '460px',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  cardImage: {
    position: 'relative',
    width: '100%',
    minHeight: '200px',
    objectFit: 'cover',
  },
}));

const Location = ({ data, ...rest }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div {...rest}>
      <SectionHeader title={t('whatWeOfferTitle')} titleVariant="h3" fadeUp />
      <Grid container spacing={2}>
        {data.map((item: any, index: number) => {
          const image = '../../data/images/virtual-office.png';
          return (
            <Grid key={index} item xs={12} sm={6} md={3} data-aos="fade-up">
              <CardProduct
                withShadow
                liftUp
                className={classes.cardRoot}
                mediaContent={
                  <>
                    <Image
                      src={item.image}
                      className={classes.cardImage}
                      alt="Office View"
                    />
                  </>
                }
                cardContent={
                  <Grid container spacing={1} style={{ padding: '16px' }}>
                    <Grid item xs={12} style={{ marginTop: -15 }}>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        align="left"
                        style={{
                          textTransform: 'uppercase',
                          fontFamily: 'Cinzel',
                          fontWeight: 400,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="left"
                      >
                        {item.address}
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={12}>
                      <LearnMoreLink
                        color="secondary"
                        title={t('whatWeOfferExploreMore')}
                        href="/memberships"
                        className={classes.exploreMore}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Location;
