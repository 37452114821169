import React, { useEffect, useState } from 'react';
import './banner.css';
import { HOSTNAME_LIVE } from 'utils/constants';

const Banner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    const getBanner = async () => {
      try {
        const res = await fetch(`${HOSTNAME_LIVE}/api/banner/`);
        const data = await res.json();
        setImage(data[0].image);
        setIsVisible(data[0].is_active);
      } catch (error) {
        console.error('Error fetching banner:', error);
      }
    };

    getBanner();
  }, []);

  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isVisible]);

  const handleClosePopup = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsVisible(false);
    }
  };

  return isVisible ? (
    <div className="popup-banner" onClick={handleClosePopup}>
      <div className="popup-image-wrapper">
        <div className="close" onClick={handleClosePopup}>
          &#x2716;
        </div>
        <img
          src={image}
          alt="Banner Image"
        />
      </div>
    </div>
  ) : null;
};

export default Banner;
