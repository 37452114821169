import landlord from './images/landlord.svg';
import serviced from './images/serviced.svg';
import flexible from './images/flexible.svg';
import experience from './images/experience.svg';
import sheraton from './images/sheraton.png';
import hyatt from './images/hyatt.png';
import intercontinental from './images/intercontinental.png';
import hilton from './images/hilton.png';
import yutopia from './images/yutopia.png';
import conrad from './images/conrad.png';
import yuhotels from './images/yuhotels.png';
import mworks from './images/mworks.png';
import ethos from './images/ethos.png';
import minimax from './images/minimax.png';
import yuluxe from './images/yuluxe.png';
import boxful from './images/boxful.png';
import virtualoffice from './images/event-space.jpg';
import coworking from './images/coworking.png';
import privateoffice from './images/private-office.png';
import eventmeetingspace from './images/meeting-room.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const data = () => {
  const { t } = useTranslation();
  const partners = [
    {
      name: '',
      logo: sheraton,
    },
    {
      name: '',
      logo: hyatt,
    },
    {
      name: '',
      logo: intercontinental,
    },
    {
      name: '',
      logo: hilton,
    },
    {
      name: '',
      logo: yutopia,
    },
    {
      name: '',
      logo: mworks,
    },
    {
      name: '',
      logo: conrad,
    },
    {
      name: '',
      logo: yuhotels,
    },
    {
      name: '',
      logo: ethos,
    },
    {
      name: '',
      logo: minimax,
    },
    {
      name: '',
      logo: yuluxe,
    },
    {
      name: '',
      logo: boxful,
    },
  ];

  const advantages = [
    {
      icon: serviced,
      title: t('whatUniqueServicedTitle'),
      subtitle: t('whatUniqueServicedSubtitle'),
    },
    {
      icon: flexible,
      title: t('whatUniqueFlexibleTitle'),
      subtitle: t('whatUniqueFlexibleSubtitle'),
    },
    {
      icon: experience,
      title: t('whatUniqueExperienceTitle'),
      subtitle: t('whatUniqueExperienceSubtitle'),
    },
  ];

  const locations = [
    {
      title: t('whatWeOfferPrivateOfficesTitle'),
      address: t('whatWeOfferPrivateOfficesContent'),
      image: privateoffice,
    },
    {
      title: t('whatWeOfferCoworkingTitle'),
      address: t('whatWeOfferCoworkingContent'),
      image: coworking,
    },
    
    {
      title: t('whatWeOfferVirtualOfficesTitle'),
      address: t('whatWeOfferVirtualOfficesContent'),
      image: virtualoffice,
    },
    {
      title: t('whatWeOfferEventsMeetingTitle'),
      address: t('whatWeOfferEventsMeetingContent'),
      image: eventmeetingspace,
    },
  ];

  return {
    partners,
    advantages,
    locations,
  };
};
